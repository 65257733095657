import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
 
    component: () => import( '../views/menu.vue'),
    children: [   // 添加子路由  子路由才能在页面中跳转
      {            //默认子路由页面
        path: '/',
      
        component: () => import( '../views/home/home.vue')
      },
      {
        path: '/home',
        name: 'home',
        component: () => import( '../views/home/home.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import( '../views/user/user.vue')
      },
      {
        path: '/movies',
        name: 'movies',
        component: () => import( '../views/movies/movies.vue')
      },
      {
        path: '/systemOS',
        name: 'systemOS',
        component: () => import( '../views/systemOS/systemOS.vue')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import( '../views/search/search.vue')
      },
      {
        path: '/detailMovie',
        name: 'detailMovie',
        component: () => import( '../views/detailMovie/detailMovie.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import( '../views/login/login.vue')
      },
      {
        path: '/updateUserPassword',
        name: 'updateUserPassword',
        component: () => import( '../views/updateUserPassword/updateUserPassword.vue')
      },
      {
        path: '/supportPay',
        name: 'supportPay',
        component: () => import( '../views/supportPay/supportPay.vue')
      },
      
      
    ]
  },
 

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
